import Entity, { field } from '../entity';
    
export default class BusinessTypesEntity extends Entity {
    @field({ type: 'int', primaryKey: true })
    public id: number;
    
    @field({ type: 'string' })
    public type: string;
    
    @field({ type: 'int' })
    public sequence: number;

    @field({ type: 'string', serverOnly: true })
    public min_app_version: string;

    @field({ type: 'string', serverOnly: true })
    public max_app_version: string;
}
